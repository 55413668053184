<template>
  <div class="settlein">
    <SettleIntop />
    <div class="main_set">
      <div class="main m-top-20">
        <div class="userinfo_header flex column " style="height:600px">
          <div class="header p-lr-20 flex j-between a-center">
            <div class="weight">修改企业超级管理员账号</div>
          </div>
          <div class="main_a flex m-top-30">
            <div class="main_b ">

              <el-form ref="form" :model="form" label-width="150px">
                <el-form-item label="新联系人名称：">
                  <el-input v-model="form.name" style="width:50%" size="mini" />
                </el-form-item>
                <el-form-item label="新联系电话：">
                  <el-input v-model="form.name" style="width:50%" size="mini" />
                </el-form-item>
                <el-form-item label="短信验证码：">
                  <el-input v-model="form.name" style=" width: 50%;" size="mini" class="form_settlein m-right-20" />
                  <el-button type="primary" size="mini">获取验证码</el-button>
                </el-form-item>
                <el-form-item label="授权函：">
                  <span class="weight theme-color">下载授权函模板</span>
                  <el-upload
                    class="upload-demo"
                    drag
                    action="https://jsonplaceholder.typicode.com/posts/"
                    multiple
                  >
                    <i class="el-icon-upload" />
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  </el-upload>
                </el-form-item>
                <el-form-item label="">
                  <div class="flex">
                    <div class="btn f12 p-lr-20">确认找回并等待审核</div>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SettleInFoot />
  </div>
</template>
<script>
import Index from '@/minxin/settlein/NumberBack'
export default Index
</script>
<style lang="scss" scoped>
@import '../../style/settlein.scss';
.main_a {
    width: 100%;
    height: 100%;

    .main_b {
        width: 50%;
        height: 200px;
        margin: 0 auto;
        .btn {
            height: 30px;
            background-color: #3e76ed;
            border-radius: 50px;
            color: #fff;
            line-height: 30px;
            text-align: center;
       }
    }
}
</style>
