import SettleIntop from '@/components/settleIntop'
import SettleInFoot from '@/components/settleInFoot'
const Index = {
  name: 'NumberBack',
  components: {
    SettleInFoot,
    SettleIntop
  },
  data() {
    return {
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      }
    }
  }
}
export default Index
